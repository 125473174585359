/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode } from 'react'
import {
  FormControl, InputLabel, Select, SelectProps,
} from '@mui/material';

interface SelectStyledProps extends Omit<SelectProps, 'children'> {
  children: ReactNode;
  placeholder?: string;
  minWidth?: string;
}

function SelectStyled({ children, ...props }: SelectStyledProps) {
  const { minWidth, label } = props;
  return (
    <FormControl sx={{ minWidth: minWidth || '500px' }}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        {...props}
      >
        {children}
      </Select>
    </FormControl>
  )
}

export default SelectStyled
