import React from 'react'
import Layout from 'components/Layout';
import JobPositionToCandidate from 'module/succession/JobPositionToCandidate';

function CompetencyAnalysis() {
  return (
    <Layout>
      <JobPositionToCandidate />
    </Layout>
  );
}

export default CompetencyAnalysis;
