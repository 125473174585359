import React, { useEffect } from 'react';
import {
  Container,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import { useUserDetailMutation } from 'services/authApi';
import { setSSOAccessToken } from 'reduxtk/userDataSlice';
import { useUserDetailQuery } from 'services/dataApi';

export default function Sso() {
  const {
    accessToken, userId, urlModule, urlPath,
  } = useParams();

  localStorage.removeItem('accessToken');
  localStorage.removeItem('userId');
  localStorage.setItem('accessToken', String(accessToken))
  localStorage.setItem('userId', String(userId))

  const {
    data,
    isLoading: isLoadingUserDetails,
  } = useUserDetailQuery({ userId });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSSOAccessToken({ accessToken, userId }));
  }, []);

  useEffect(() => {
    if (data) {
      if (urlModule && urlPath) {
        navigate(`/${urlModule}/${urlPath}`);
      } else {
        navigate(`/${urlModule}`);
      }
    }
  }, [data]);

  const loading = (
    <Box sx={{
      display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
    }}
    >
      <CircularProgress />
      <Typography> Login you in, please wait.</Typography>
    </Box>
  );

  return (
    <Container
      maxWidth={false}
      style={{
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh', backgroundImage: 'url("https://lsglm500.learnsoft.com/LSGLM/Login/images/back2.jpg")',
      }}
    >
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        {isLoadingUserDetails && loading}
      </Box>
    </Container>
  );
}
