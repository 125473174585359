import React from 'react'
import { Box, Typography, Stack } from '@mui/material'
import { useUserToJobPositionQuery } from 'services/dataApi'
import { RequirementGroup } from 'reduxtk/interfaces'
import LoadingIcon from 'components/LoadingIcon'
import {
  JobRequirement, QualifyColor, QualifyStatus,
} from './shared'
import { getLabelByValue } from './CandidateList'
import { CircleCard } from './CandidateInfo'
import GroupTable from './GroupTable'

function getRequiredMetIcon(metRequired: string) {
  return (
    <Box
      display="flex"
      alignContent="center"
      justifyContent="center"
      justifyItems="center"
      bgcolor={QualifyColor[metRequired]}
      color="white"
      width="80px"
    >
      <Typography variant="body1" gutterBottom mb={0}>
        {`${QualifyStatus[metRequired]}`}
      </Typography>
    </Box>
  )
}

export default function CandidateToJobPosition({ showQualify, candidateId, jobPositionId }:
  {candidateId: Number, jobPositionId: Number, showQualify?: Boolean}) {
  const {
    data: userToJobData,
    isLoading: isLoadingUserToJob,
  } = useUserToJobPositionQuery({ jobPositionId, userId: candidateId });

  const rowsJobPosition: JobRequirement[] = userToJobData?.requirementCandidateMet
    ?.map((required: RequirementGroup) => {
      let positionRequirement = '';
      let requirements = required?.requirements;
      if (required?.requirements?.length === 1) {
        positionRequirement = requirements[0]?.positionRequirement;
        requirements = [];
      }

      return (
        {
          ...required,
          positionRequirement,
          requirements,
          matchType: required?.isRequired ? 'Required' : 'Preferred',
          requirementMet: getRequiredMetIcon(String(required?.requirementMet)),
        })
    })

  return (
    <Stack gap={2}>
      { !isLoadingUserToJob
        ? (
          <>
            {' '}
            {
        showQualify && userToJobData?.overAllCandidateQualify && (
        <Stack flexDirection="row" gap={4}>
          <CircleCard
            color={getLabelByValue(userToJobData?.requiredCandidateQualify || 0)?.color}
            value={Number(userToJobData?.requiredCandidateQualify)}
            type="Required"
          />
          <CircleCard
            color="#eeeeee"
            value={Number(userToJobData?.preferredCandidateQualify)}
            type="Preferred"
          />
          <CircleCard
            color="#f5f5f5"
            value={Number(userToJobData?.overAllCandidateQualify)}
            type="Overall"
          />

        </Stack>
        )
      }
            <GroupTable
              rowsJobPosition={rowsJobPosition}
            />
          </>
        )
        : <LoadingIcon />}
    </Stack>

  )
}
