import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';
import { IState } from 'reduxtk/interfaces';

function ProtectedRoutes() {
  // here we have to define a function to check if the user is logged or not
  const { authenticated } = useSelector((state: IState) => state?.userData);
  if (!authenticated) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
}

export default ProtectedRoutes;
