import React from 'react'
import Layout from 'components/Layout';
import Succession from 'module/succession';

function Dashboard() {
  return (
    <Layout>
      <Succession />
    </Layout>
  );
}

export default Dashboard;
