/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, {
  useState, MouseEvent, ChangeEvent, useEffect,
} from 'react';
import { useTheme } from '@mui/material/styles';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LastPageIcon from '@mui/icons-material/LastPage';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import {
  TablePaginationActionsProps, ICustomPaginationActionsTable, TableSortDirection, ITableHeader, TColumns, TRows,
} from 'components/CustomTable/interface';
import sortingArrayBy from './helper';

const ROW_HEIGHT = '41px';
function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const {
    count, page, rowsPerPage, onPageChange,
  } = props;

  const handleFirstPageButtonClick = (
    event: MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
function CollapseRow({
  row,
  columns,
  collapseAllRow,
  showAllColumns,
  handleRowClick,
  isSelected,
  showSelectCheckbox,
}:
{ row: TRows, columns: TColumns[], collapseAllRow: boolean, showAllColumns: boolean, handleRowClick: Function, isSelected: Function, showSelectCheckbox: boolean}) {
  const [open, setOpen] = useState(!collapseAllRow);
  return (
    <>
      <TableRow
        sx={{ height: ROW_HEIGHT }}
      >
        <TableCell
          style={{ padding: 0 }}
          colSpan={3}
        >
          {showAllColumns && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center',
                p: 0,
              }}
            >
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
              {row.courseType}
            </Box>
          )}
        </TableCell>
        {showAllColumns && (
          <TableCell
            colSpan={(columns?.length || 0) - 1}
            style={{ padding: 0 }}
          >
            {row?.courseName}
          </TableCell>
        )}
      </TableRow>
      {showAllColumns && open && (React.Children.toArray(row?.details?.map((_detailRow: TRows) => {
        const isItemSelected = isSelected(_detailRow.id);
        return (
          <TableRow
            sx={{ backgroundColor: (theme) => theme.palette.grey[100], height: ROW_HEIGHT }}
            onClick={(event) => handleRowClick(event, _detailRow?.id)}
          >
            <TableCell sx={{ padding: '4px' }}>
              {showSelectCheckbox && (
              <Checkbox
                color="primary"
                checked={isItemSelected}
                inputProps={{
                  'aria-label': `checkBox-${row?.id}`,
                }}
              />
              )}
            </TableCell>
            <TableCell
              sx={{ width: '20px' }}
            />
            {React.Children.toArray(columns?.map((column: TColumns) => {
              const padding: number | string = 0;

              return (
                !column?.hidden && (
                <TableCell
                  sx={{
                    padding,
                    maxWidth: column?.width,
                  }}
                >
                  {_detailRow[column?.column ?? 'id']}
                </TableCell>
                )
              )
            }))}
          </TableRow>
        )
      }))
      )}
    </>
  )
}
function DefaultRow({
  row, columns, handleRowClick, isSelected, showSelectCheckbox, highlightRowBy,
}: { row: TRows, columns: TColumns[], handleRowClick: Function, isSelected: Function, showSelectCheckbox:boolean, highlightRowBy?: number }) {
  const isItemSelected = isSelected(row.id);
  const hasBgColor = highlightRowBy === row?.id ? '#f5f5f5' : 'none';
  return (
    <TableRow
      sx={{ height: ROW_HEIGHT, backgroundColor: hasBgColor }}
      onClick={(event) => handleRowClick(event, row.id)}
    >
      {showSelectCheckbox && (
      <TableCell padding="checkbox">

        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            'aria-labelledby': `checkBox-${row?.id}`,
          }}
        />

      </TableCell>
      )}
      <TableCell
        key="default-row-arrow"
        sx={{ width: '20px' }}
      />
      {React.Children.toArray(columns?.map((column: TColumns) => (
        !column?.hidden && (
          <TableCell
            sx={{
              p: column?.padding || 0,
              width: column?.width,
              textAlign: column?.align || 'left',
            }}
          >
            {row[column?.column ?? 'id']}
          </TableCell>
        )
      )))}
    </TableRow>
  )
}
function Row({
  row,
  columns,
  collapseAllRow,
  showAllColumns,
  handleRowClick,
  isSelected,
  showSelectCheckbox,
  highlightRowBy,
}: {
  row: TRows,
  columns: TColumns[],
  collapseAllRow: boolean,
  showAllColumns: boolean,
  handleRowClick: Function,
  isSelected: Function,
  showSelectCheckbox: boolean,
  highlightRowBy?: number
}) {
  if (row?.isCollapsable) {
    return (
      <CollapseRow
        row={row}
        columns={columns}
        collapseAllRow={collapseAllRow}
        showAllColumns={showAllColumns}
        handleRowClick={handleRowClick}
        isSelected={isSelected}
        showSelectCheckbox={showSelectCheckbox}
      />
    )
  }
  return (
    <DefaultRow
      row={row}
      columns={columns}
      handleRowClick={handleRowClick}
      isSelected={isSelected}
      showSelectCheckbox={showSelectCheckbox}
      highlightRowBy={highlightRowBy}
    />
  )
}
function TableHeader({
  column,
  sort,
  sortDirection,
  handleDescendingSort,
  handleAscendingSort,
}: ITableHeader) {
  const getSorting = () => {
    const columnName = column?.column.replace('Icon', '');
    const renderAsc = columnName === sort && TableSortDirection.DESCENDING === sortDirection

    return renderAsc ? (
      <ArrowDownwardIcon
        sx={{ cursor: 'pointer' }}
        color="primary"
        fontSize="small"
        name={columnName}
        onClick={(e) => handleAscendingSort(e?.currentTarget.getAttribute('name') || '')}
      />
    ) : (
      <ArrowUpwardIcon
        sx={{ cursor: 'pointer' }}
        color="primary"
        fontSize="small"
        name={columnName}
        onClick={(e) => handleDescendingSort(e?.currentTarget.getAttribute('name') || '')}
      />
    )
  }
  return (
    <TableCell
      padding="none"
      size="medium"
      variant="head"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: column?.alignTitle || 'left',
          p: 0,
        }}
      >
        {column?.name}
        {column?.sortable && getSorting()}
      </Box>
    </TableCell>
  )
}

function SwitchLabels({ columnsList, onChangeColumn }: { columnsList: TColumns[], onChangeColumn: Function }) {
  return (
    <FormGroup sx={{ p: 2 }}>
      {React.Children.toArray(columnsList?.map((column) => (
        <FormControlLabel
          control={(
            <Switch
              checked={!column?.hidden}
              onChange={() => onChangeColumn(column?.column)}
            />
)}
          label={column?.name}
        />
      )))}
    </FormGroup>
  );
}
export default function CustomTable(
  {
    rows, columns, collapseAllRow = true, defaultSort, showSelectCheckbox = false, showMenuActions = false, highlightRowBy,
  }: ICustomPaginationActionsTable,
) {
  const [data, setData] = useState<any[]>([]);
  const [columnsList, setColumnsList] = useState<TColumns[]>(columns);
  const [page, setPage] = useState<number>(0);
  const [sortDirection, setSortDirection] = useState<string>(TableSortDirection.DESCENDING);
  const [sort, setSort] = useState<string | undefined>(defaultSort);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [selected, setSelected] = React.useState<readonly number[]>([]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClickConf = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseConf = () => {
    setAnchorEl(null);
  };
  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAscendingSort = (key: string) => {
    setSort(key.replace('Icon', ''));
    setSortDirection(TableSortDirection.ASCENDING);
    setPage(0);
  };

  const handleDescendingSort = (key: string) => {
    setSort(key.replace('Icon', ''));
    setSortDirection(TableSortDirection.DESCENDING);
    setPage(0);
  };

  function handleHiddenColumn(columnId: string) {
    setColumnsList((_col) => _col.map((col) => {
      if (col.column === columnId) {
        return {
          ...col,
          hidden: !col.hidden,
        };
      }
      return col;
    }))
  }

  function handleHiddenAllColumn(showHide: boolean) {
    setColumnsList((_col) => _col.map((col) => ({
      ...col,
      hidden: !showHide,
    })))
  }

  const showAllColumns = Boolean(columnsList.some((column) => !column.hidden));

  const totalRows: number = data.reduce(
    (accumulator: number, det: TRows) => accumulator + (det.details?.length || 0) + 1,
    0,
  );
  const isSelected = (id: number) => selected.indexOf(id) !== -1;
  const handleRowClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((item: TRows) => {
        const ids = [item?.id];

        if (item.details && Array.isArray(item.details)) {
          item?.details?.forEach((det: TRows) => {
            ids.push(det?.id)
          });
        }

        return ids;
      }).flat()
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  useEffect(() => {
    setData(sortingArrayBy({ arrayToSort: rows, sortBy: sort || 'desc', sortDirection }))
  }, [rows, sort, sortDirection]);

  return (
    <TableContainer data-testid="custom-pagination-table">
      {showMenuActions && (
      <Stack sx={{
        display: 'flex',
        flexDirection: 'row-reverse',
      }}
      >
        <ViewColumnIcon
          sx={{ cursor: 'pointer' }}
          color="primary"
          fontSize="large"
          onClick={(e) => handleClickConf(e)}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleCloseConf}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <SwitchLabels
            columnsList={columnsList}
            onChangeColumn={(columnId: string) => handleHiddenColumn(columnId)}
          />
          <Stack direction="row" justifyContent="space-between" p={1} gap={1}>
            <Button
              variant="outlined"
              onClick={() => {
                handleHiddenAllColumn(false)
              }}
            >
              Hide All

            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                handleHiddenAllColumn(true)
              }}
            >
              Show All

            </Button>
          </Stack>
        </Popover>
      </Stack>
      )}
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableHead sx={{ background: (theme) => theme.palette.grey[100] }}>
          <TableRow sx={{ height: '53px' }}>
            {showSelectCheckbox && (
            <TableCell padding="checkbox">

              <Checkbox
                color="primary"
                indeterminate={selected.length > 0 && selected.length < totalRows}
                checked={totalRows > 0 && selected.length === totalRows}
                onChange={(event) => handleSelectAllClick(event)}
                inputProps={{
                  'aria-label': 'select all',
                }}
              />

            </TableCell>
            )}
            <TableCell key="table-header-row-arrow" sx={{ width: '20px' }} />
            {React.Children.toArray(columnsList?.map((column) => (
              !column?.hidden && (
                <TableHeader
                  column={column}
                  sort={sort}
                  sortDirection={sortDirection}
                  handleDescendingSort={handleDescendingSort}
                  handleAscendingSort={handleAscendingSort}
                />
              )
            )))}
          </TableRow>
        </TableHead>
        <TableBody>
          {React.Children.toArray((rowsPerPage > 0
            ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : data
          )?.map((row) => (
            <Row
              row={row}
              columns={columnsList}
              collapseAllRow={collapseAllRow}
              showAllColumns={showAllColumns}
              handleRowClick={handleRowClick}
              isSelected={isSelected}
              showSelectCheckbox={showSelectCheckbox}
              highlightRowBy={highlightRowBy}
            />
          )))}
          {data?.length === 0 && (
            <TableRow style={{ height: ROW_HEIGHT }} data-testid="empty-rows">
              <TableCell colSpan={(columnsList?.length || 0) + 2} align="center"> No records </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={(columnsList?.length || 0) + 2}
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
