import React, { useState } from 'react';
import {
  Container,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import { useGetLearnsoftTokenAndLogginMutation } from 'services/authApi';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isStringEmptyOrInvalid } from 'utils/helpers';
import { NAVIGATION_URL } from 'utils/constants';

function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [GetLearnsoftTokenAndLoggin, { isLoading }] = useGetLearnsoftTokenAndLogginMutation();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorLogin, setErrorLogin] = useState(false);

  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();

    try {
      const payload = await GetLearnsoftTokenAndLoggin({
        companyName: 'centerlineApp',
        username,
        password,
      }).unwrap();
      const userInfo: any = payload;
      if (userInfo?.Validate === 'true') {
        if (userInfo?.admin) {
          navigate(`/${NAVIGATION_URL.ADMIN}/${NAVIGATION_URL.DASHBOARD}`)
        } else {
          navigate('/')
        }
      } else {
        setErrorLogin(true)
      }
    } catch (error) {
      navigate('/error')
    }
  };
  const loading = (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
  );

  return (
    <Container
      maxWidth={false}
      style={{
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh', backgroundImage: 'url("https://lsglm500.learnsoft.com/LSGLM/Login/images/back2.jpg")',
      }}
    >
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Typography component="h1" variant="h5">
          { isLoading ? t('loading') : t('welcome')}
        </Typography>
        <br />
        {isLoading ? loading : (
          <Box component="form" noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="userName"
              label={t('userName')}
              name="userName"
              autoComplete="userName"
              onChange={(event) => setUsername(event.target.value)}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('password')}
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(event) => setPassword(event.target.value)}
            />
            {errorLogin && (
            <Typography variant="body1" color="error">
              {t('invalid-user-password')}
            </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={(event) => handleSubmit(event)}
              sx={{ mt: 3, mb: 2 }}
              role="button"
              aria-label="Sign In"
              disabled={!username || !password || isStringEmptyOrInvalid('companyName')}
            >
              Sign In
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
}

export default Login;
