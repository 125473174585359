import React from 'react';
import {
  Container, Box, Typography, Button,
} from '@mui/material';

export default function ErrorLogin() {
  return (
    <Container
      maxWidth={false}
      style={{
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh', backgroundImage: 'url("https://lsglm500.learnsoft.com/LSGLM/Login/images/back2.jpg")',
      }}
    >
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Typography className="secondary-text" variant="h6">
          Unable to login with Single Sign On, try to login again.
        </Typography>
        <Button variant="contained">Return home</Button>
      </Box>
    </Container>
  );
}
