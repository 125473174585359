/* eslint-disable array-callback-return */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { colummn } from './shared';

export default function BasicTable({ rows, columns }: {rows: any[], columns: colummn[]}) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ background: (theme) => theme.palette.grey[100] }}>
          <TableRow>
            {React.Children.toArray(columns?.map((column: colummn) => (
              <TableCell align={column?.align} width={column?.width}>
                {column?.name}
              </TableCell>
            )))}
          </TableRow>
        </TableHead>
        <TableBody>
          {React.Children.toArray(rows?.map((row: any) => (
            <TableRow>

              {React.Children.toArray(columns?.map((column) => (
                <TableCell align={column?.align}>
                  {row[column?.column || 'id']}
                </TableCell>
              )))}
            </TableRow>
          )))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
