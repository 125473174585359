import React, { useState } from 'react'
import {
  Chip, Grid, Stack, Typography,
} from '@mui/material'
import ViewFormLayout from 'components/ViewFormLayout'
import SearchBar from 'components/SearchBar'
import DialogModal from 'components/DialogModal'
// import { useParams } from 'react-router-dom'
import { useJobPositionSummaryQuery, useGetJobPositionsQuery } from 'services/dataApi'
import { ICandidateSummary, IState } from 'reduxtk/interfaces'
import LoadingIcon from 'components/LoadingIcon'
import { useSelector } from 'react-redux'
import { JobPosition } from 'services/interfaces'
import Chart from './Chart'
import CandidateList, { getHierarchyIcon } from './CandidateList'
import CandidateInfo from './CandidateInfo'

const CHART_FILTER_INIT = 'All';
const SEARCH_INIT = '';
export default function Succession() {
  const {
    userId,
    firstName,
    lastName,
    jobPositionId,
    jobPositionDescription,
    currentHierarchy,
  } = useSelector((state: IState) => state.userData.user);

  const {
    data: jobPositionData,
    isLoading: isLoadingJobPosition,
  } = useGetJobPositionsQuery();
  const {
    data: candidateSummaryData,
    isLoading: isLoadingSummary,
  } = useJobPositionSummaryQuery({ jobPositionId });

  const [chartFilter, setChartFilter] = useState<string>(CHART_FILTER_INIT);
  const [searchQuery, setSearchQuery] = useState<string>(SEARCH_INIT);
  const [selectedCandidate, setSelectedCandidate] = useState<ICandidateSummary | null>(null);

  const jobPositionInfo = (jobPositionData)?.find(
    (job: JobPosition) => job?.jobPositionId === Number(jobPositionId),
  );

  function getChartBox() {
    return (
      <Grid item sm={12} lg={3}>
        <ViewFormLayout testId="succession">
          <ViewFormLayout.Title>
            <Typography variant="subtitle1">Candidates Match for job position:</Typography>
            <Typography variant="subtitle1">{`${jobPositionDescription}`}</Typography>
          </ViewFormLayout.Title>
          <ViewFormLayout.ActionBar>
            <Stack direction="row" spacing={1}>
              {chartFilter !== CHART_FILTER_INIT && <Chip label={chartFilter} variant="outlined" onDelete={() => setChartFilter(CHART_FILTER_INIT)} />}
            </Stack>
          </ViewFormLayout.ActionBar>
          <ViewFormLayout.Body>
            <Chart
              onSelect={(item: string) => setChartFilter(item)}
              candidates={candidateSummaryData}
            />
          </ViewFormLayout.Body>
        </ViewFormLayout>
      </Grid>
    )
  }

  function getSuccesionBox() {
    return (
      <Grid item sm={12} lg={9}>
        <ViewFormLayout testId="succession">
          <ViewFormLayout.Title>
            <Stack direction="column" spacing={1}>
              <Typography variant="subtitle1">{`Succession Planning: ${firstName} ${lastName} (${userId})`}</Typography>
              <Stack direction="row" gap={1}>
                <Typography variant="subtitle1">{`Job position: ${jobPositionDescription}`}</Typography>
                {currentHierarchy && getHierarchyIcon(currentHierarchy)}
              </Stack>
            </Stack>
          </ViewFormLayout.Title>
          {!selectedCandidate && (
            <ViewFormLayout.ActionBar>
              <SearchBar
                onSearch={(text: string) => {
                  setSearchQuery(text)
                }}
              />
            </ViewFormLayout.ActionBar>
          )}
          <ViewFormLayout.Body>
            <CandidateList
              searchValue={searchQuery}
              competencyFilter={chartFilter}
              candidateSummaryData={candidateSummaryData}
              onSelect={(candidate: ICandidateSummary) => setSelectedCandidate(candidate)}
              highlightby={userId}
            />
          </ViewFormLayout.Body>
        </ViewFormLayout>
      </Grid>
    )
  }

  function getLoadingBox() {
    return (
      <Grid item sm={12} lg={12} justifyContent="center">
        <LoadingIcon />
      </Grid>
    )
  }

  function getErrorBox() {
    return (
      <Grid item sm={12} lg={12} justifyContent="center" p={2}>
        <Typography textAlign="center">Please assign Job Position to get succession analysis!</Typography>
      </Grid>
    )
  }

  return (

    <ViewFormLayout testId="succession">
      <ViewFormLayout.Body>
        {jobPositionId ? (
          <Grid container spacing={2} p={2}>
            {!(isLoadingJobPosition || isLoadingSummary) ? (
              <>
                {getChartBox()}
                {getSuccesionBox()}
              </>
            ) : getLoadingBox()}
          </Grid>
        ) : getErrorBox()}
      </ViewFormLayout.Body>
      <DialogModal
        open={Boolean(selectedCandidate)}
        onClose={() => {
          setSearchQuery(SEARCH_INIT);
          setSelectedCandidate(null)
        }}
      >
        <DialogModal.Title>
          <Typography>Candidate Information</Typography>
        </DialogModal.Title>
        <DialogModal.Content>
          {selectedCandidate && jobPositionInfo && (
            <CandidateInfo
              candidate={selectedCandidate}
              jobPositionInfo={jobPositionInfo}
            />
          )}
        </DialogModal.Content>
      </DialogModal>
    </ViewFormLayout>

  )
}
