/* eslint-disable max-len */
import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { PERCENT_LABELS_COLOR } from 'utils/constants';
import { EmployeeSummaryCriticalRisk } from 'services/interfaces';

function QualificationIcon(label: string, quantity: number) {
  return (
    <Box
      width={70}
      borderRadius="50%"
      height={70}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      bgcolor={PERCENT_LABELS_COLOR[label]}
      color="white"
    >
      <Typography variant="h6" gutterBottom mb={0}>
        {quantity}
      </Typography>
    </Box>
  )
}

function QualificationLabel(label: string) {
  return (
    <Stack flexDirection="column" alignItems="center">
      <Typography variant="subtitle2" gutterBottom mb={0}>
        {`Candidates have ${label}`}
      </Typography>
      <Typography variant="subtitle2" gutterBottom mb={0}>
        Required Match
      </Typography>
    </Stack>
  )
}

const DEFAULT_DATA = [
  {
    percentDescription: '< 50%',
    groupCount: 0,
  },
  {
    percentDescription: '50% - 60%',
    groupCount: 0,
  },
  {
    percentDescription: '60% - 70%',
    groupCount: 0,
  },
  {
    percentDescription: '70% - 85%',
    groupCount: 0,
  },
  {
    percentDescription: '80% - 100%',
    groupCount: 0,
  },
];
export default function EmployeesResume({ employeeSummary = DEFAULT_DATA }:{employeeSummary: EmployeeSummaryCriticalRisk[]}) {
  const totalEmployeesCount = employeeSummary.reduce((accumulator, currentValue) => accumulator + currentValue.groupCount, 0);
  return (
    <Box display="flex" flexDirection="column" gap={1} pt={1}>
      <Typography sx={{ fontWeight: 600 }}>{`Total Candidates: ${totalEmployeesCount}`}</Typography>
      { employeeSummary?.map((summary: EmployeeSummaryCriticalRisk) => (
        <Stack flexDirection="row" gap={3} alignItems="center">
          {QualificationIcon(summary?.percentDescription, summary?.groupCount)}
          {QualificationLabel(summary?.percentDescription)}
        </Stack>
      ))}
    </Box>
  )
}
