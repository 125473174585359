import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';
import { IState } from 'reduxtk/interfaces';

function ProtectedAdminRoutes() {
  const { admin } = useSelector((state: IState) => state.userData);

  if (!admin) {
    return <Navigate to="/admin-error" />;
  }

  return <Outlet />;
}

export default ProtectedAdminRoutes;
