import React from 'react'

export const JOB_POSITION_EXAMPLE = 10484;
export const JOB_POSITION_DESCRIPTION_EXAMPLE = 'Assist Bureau Chief (PCE II) (BRS Research Unit) (Professional Civil Engineer)';

export const QualifyStatus = {
  true: 'Yes',
  false: 'No',
}

export const QualifyColor = {
  true: '#4caf50',
  false: '#f44336',
}
export type PERFORMANCE_STATUS_TYPE = 'high' | 'medium' | 'low';

export interface colummn {
  name: string;
  column: string;
  width: string;
  align: 'center' | 'left' | 'right';
}
export interface ICompetency {
  CompetencyId: number;
  CompetenciesSkills: string;
  Required: string;
}

export interface JobRequirement {
  ID: number;
  requirementType: string;
  PositionRequirement: string;
  CandidateQualification: string;
  requirementMet: string | React.ReactNode;
  positionRequirement: string;
  matchType: string;
  requirements: any[]
}
export interface ICandidate {
    CandidateId: number;
    Name: string;
    PositionTitle: string;
    Qualification?:number;
    Action?: string;
  }
