import { authApi } from 'services/authApi';
import { dataApi } from 'services/dataApi';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { setupListeners } from '@reduxjs/toolkit/query';
import { yellowfinApi } from 'services/yellowfinApi';
import storage from 'redux-persist/lib/storage';
import { thunk } from 'redux-thunk';
import userProfileReducer from './userProfileSlice';
import userDataReducer from './userDataSlice';

const persistConf = {
  key: 'root',
  storage,
  whitelist: ['userProfile', 'userDataReducer'],
}

const rootReducer = combineReducers({
  userProfile: userProfileReducer,
  userData: userDataReducer,
  [authApi.reducerPath]: authApi.reducer,
  [dataApi.reducerPath]: dataApi.reducer,
  [yellowfinApi.reducerPath]: yellowfinApi.reducer,
})

const persistedReducer = persistReducer(persistConf, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
    .concat(thunk)
    .concat(authApi.middleware)
    .concat(dataApi.middleware)
    .concat(yellowfinApi.middleware),
});

setupListeners(store.dispatch);

export default store;
