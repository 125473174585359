import { BaseQueryApi, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IState } from 'reduxtk/interfaces';
import { NAVIGATION_URL } from 'utils/constants';
import { isUserAnAdmin } from 'utils/helpers';

const learnsoftClientId: string = process.env.REACT_APP_LEARNSOFT_CLIENT_ID as string;
const learnsoftClientSecret: string = process.env.REACT_APP_LEARNSOFT_CLIENT_SECRET as string;
const learnsoftGrantType: string = process.env.REACT_APP_LEARNSOFTGRANTTYPE as string;
const baseUrlApi = process.env.REACT_APP_BASE_URL;

type dataResult = {
    WebApiClientSecret?: string;
    WebApiClientID?: string;
    PrimaryColor?: string;
}

type organizationResultData = {
  access_token: string;
}

interface ILoginResponse {
  EndDate: string;
  ErrorMsg: string;
  Password: string;
  ReportURL: string;
  StartDate: string;
  UserCompanyParameters: string;
  userId: string;
  UserName: string;
  UserPhotoPath: string;
  UserTranscriptPath: string;
  Validate: string;
}
export const authApi = createApi({
  reducerPath: 'authServerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrlApi,
    prepareHeaders: (headers: Headers, api: Pick<
      BaseQueryApi,
      'getState' | 'extra' | 'endpoint' | 'type' | 'forced'
    >) => {
      const getState = api?.getState() as IState;
      const { accessToken } = getState.userProfile || null;

      // If we have a token set in state, let's assume that we should be passing it.
      if (accessToken) {
        headers.set('authorization', `Bearer ${accessToken}`);
      }

      return headers;
    },
    validateStatus: (response: Response): boolean => {
      if (response.status === 401) {
        let rediretUrl = '/login';
        if (window.location.href.includes('sso')) {
          rediretUrl = `/${NAVIGATION_URL.ERROR_LOGIN}`;
        }

        window.location.href = rediretUrl;
        localStorage.removeItem('accessToken');
        localStorage.removeItem('authenticated');
        localStorage.removeItem('userId');
        return false
      }
      return true;
    },
  }),

  endpoints: (builder) => ({
    getLearnsoftTokenAndLoggin: builder.mutation({
      async queryFn(
        args,
        x,
        extraOptions,
        fetchWithBQ,
      ) {
        try {
          const { companyName } = args;
          const learnsoftTokenResult: any = await fetchWithBQ({
            url: 'token',
            method: 'POST',
            body: new URLSearchParams({
              client_id: learnsoftClientId,
              client_secret: learnsoftClientSecret,
              grant_type: learnsoftGrantType,
            }),
          });
          if (learnsoftTokenResult.error) {
            return { error: learnsoftTokenResult.error };
          }
          const accessToken = learnsoftTokenResult?.data?.access_token;
          const result = await fetchWithBQ({
            url: 'api/Users/ValidateCompanyDetails',
            method: 'POST',
            body: { CompanyName: companyName },
            headers: {
              authorization: `Bearer ${accessToken}`,
            },
          });

          const data: dataResult = result?.data as dataResult;
          const webApiClientSecret = data?.WebApiClientSecret as string;
          const webApiClientID = data?.WebApiClientID as string;
          const PrimaryColor = data?.PrimaryColor as string;
          const organizationTokenResult = await fetchWithBQ({
            url: 'token',
            method: 'POST',
            body: new URLSearchParams({
              client_id: webApiClientID,
              client_secret: webApiClientSecret,
              grant_type: learnsoftGrantType,
            }),
          });
          const organizationTokenResultData:
          organizationResultData = organizationTokenResult?.data as organizationResultData;
          const logginAccessToken = organizationTokenResultData.access_token;
          const storeDate = organizationTokenResult?.data;
          const loggin = await fetchWithBQ({
            url: 'api/users/Validate',
            method: 'POST',
            body: {
              Username: args.username,
              password: args.password,
            },
            headers: {
              authorization: `Bearer ${logginAccessToken}`,
            },
          });
          if (loggin.meta?.response?.status === 401) {
            return { data: { companyName } };
          }
          const logginFata: ILoginResponse = loggin?.data as ILoginResponse;
          const combinedData = {
            ...(storeDate as Record<string, unknown>),
            ...logginFata,
            companyName,
            PrimaryColor,
          };
          const userInfo: any = await fetchWithBQ({
            url: 'api/users/UserDetails',
            method: 'POST',
            body: {
              userId: logginFata?.userId,
            },
            headers: {
              authorization: `Bearer ${logginAccessToken}`,
            },
          });
          const user = userInfo?.data?.Users?.[0]
          const admin = isUserAnAdmin(user?.UserGroups)
          return { data: { ...combinedData, user, admin } };
        } catch {
          return { error: { error: 'An error on loggin process happens' } };
        }
      },
    }),
    userDetail: builder.mutation({
      query: (args) => ({
        url: 'api/users/UserDetails',
        method: 'POST',
        body: {
          ...args,
        },
      }),
    }),
  }),
});

export const {
  useGetLearnsoftTokenAndLogginMutation,
  useUserDetailMutation,
} = authApi;
