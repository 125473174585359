/* eslint-disable no-unused-expressions */
import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import sortingArrayBy from 'utils/helpers';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import RequirementDetail from './RequirementDetail';
import { JobRequirement } from './shared';

function Row(props: {
    rowsJobPosition: JobRequirement }) {
  const { rowsJobPosition } = props;
  const [open, setOpen] = React.useState(false);
  const hasRequirements = rowsJobPosition?.requirements?.length > 0;
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          {hasRequirements && (
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          )}
        </TableCell>
        <TableCell>{rowsJobPosition?.requirementType}</TableCell>
        <TableCell>{rowsJobPosition?.positionRequirement}</TableCell>
        <TableCell>{rowsJobPosition?.matchType}</TableCell>
        <TableCell>{rowsJobPosition?.requirementMet}</TableCell>
      </TableRow>
      {hasRequirements && (
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0, paddingTop: 0, margin: 1,
          }}
          colSpan={5}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{
              margin: 1,
              maxWidth: '60%',
            }}
            >
              <RequirementDetail requirements={rowsJobPosition?.requirements} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      )}
    </>
  );
}
export default function CollapsibleTable({ rowsJobPosition }: {rowsJobPosition: JobRequirement[]}) {
  const [data, setData] = useState(rowsJobPosition);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [sort, setSort] = useState<string>('requirementType');

  const handleAscendingSort = (key: string) => {
    setSort(key.replace('Icon', ''));
    setSortDirection('asc');
  };

  const handleDescendingSort = (key: string) => {
    setSort(key.replace('Icon', ''));
    setSortDirection('desc');
  };

  const getSorting = (name: string) => {
    const renderAsc = name === sort && sortDirection === 'desc'

    return renderAsc ? (
      <ArrowDownwardIcon
        sx={{ cursor: 'pointer' }}
        color="primary"
        fontSize="small"
        name={name}
        onClick={(e) => handleAscendingSort(e?.currentTarget.getAttribute('name') || '')}
      />
    ) : (
      <ArrowUpwardIcon
        sx={{ cursor: 'pointer' }}
        color="primary"
        fontSize="small"
        name={name}
        onClick={(e) => handleDescendingSort(e?.currentTarget.getAttribute('name') || '')}
      />
    )
  }

  useEffect(() => {
    setData(sortingArrayBy({ arrayToSort: data, sortBy: sort, sortDirection }))
  }, [rowsJobPosition, sort, sortDirection]);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead sx={{ background: (theme) => theme.palette.grey[100] }}>
          <TableRow>
            <TableCell />
            <TableCell>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'left',
                  p: 0,
                }}
              >
                Required Type
                { getSorting('requirementType')}
              </Box>
            </TableCell>
            <TableCell sx={{
              width: 700,
              maxWidth: 700,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'left',
                  p: 0,
                }}
              >
                Position Requirement
                { getSorting('positionRequirement')}
              </Box>
            </TableCell>
            <TableCell>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'left',
                  p: 0,
                }}
              >
                Match Type
                { getSorting('matchType')}
              </Box>
            </TableCell>
            <TableCell>Requirement Met</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <Row
              rowsJobPosition={row}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
