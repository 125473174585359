import React from 'react'
import { Box, Typography } from '@mui/material'
import { IRequirementCandidate } from 'reduxtk/interfaces'
import {
  QualifyColor, QualifyStatus, colummn,
} from './shared'

import BasicTable from './BasicTable'

function getRequiredMetIcon(metRequired: string) {
  return (
    <Box
      display="flex"
      alignContent="center"
      justifyContent="center"
      justifyItems="center"
      bgcolor={QualifyColor[metRequired]}
      color="white"
      width="80px"
    >
      <Typography variant="body1" gutterBottom mb={0}>
        {`${QualifyStatus[metRequired]}`}
      </Typography>
    </Box>
  )
}

export default function RequirementDetail({ requirements }:
  {requirements: any[]}) {
  const rowsJobPosition: any[] = requirements?.map((required: IRequirementCandidate) => (
    {
      ...required,
      matchType: required?.isRequired ? 'Required' : 'Prefderred',
      requirementMet: getRequiredMetIcon(String(required?.requirementMet)),
    }))

  const columnsJobPosition :colummn[] = [
    {
      name: 'Position Requirement',
      column: 'positionRequirement',
      width: '300px',
      align: 'left',
    },
    {
      name: 'Requirement Met',
      column: 'requirementMet',
      width: '200px',
      align: 'left',
    },
  ];
  return (
    <BasicTable rows={rowsJobPosition} columns={columnsJobPosition} />

  )
}
