import React, { useState } from 'react';
import {
  Box, Button, IconButton, InputAdornment,
} from '@mui/material';
import TextFieldStyled from 'components/TextFieldStyled';
import CloseIcon from '@mui/icons-material/Close';

interface SearchBarProps {
  onSearch: Function;
}
const SEARCH_INIT = '';

function SearchBar({
  onSearch,
}: SearchBarProps) {
  const [searchQuery, setSearchQuery] = useState<string>(SEARCH_INIT);

  const handleSearch = () => {
    onSearch(searchQuery);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleInputChangeEnter = (event: any) => {
    if (event?.key === 'Enter') {
      event.preventDefault();
      setSearchQuery(event.target.value);
      onSearch(event.target.value);
    }
  };

  const inputAdornment = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => {
          setSearchQuery(SEARCH_INIT)
          onSearch(SEARCH_INIT);
        }}
        edge="end"
      >
        {searchQuery && <CloseIcon />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" gap={1} bgcolor="white" p={0.5} width="100%">
      <TextFieldStyled
        type="text"
        placeholder="Search by candidate name"
        value={searchQuery}
        onChange={handleInputChange}
        onKeyDown={handleInputChangeEnter}
        width="100%"
        InputProps={
          {
            endAdornment: inputAdornment,
          }
        }
      />

      <Button sx={{ textTransform: 'none' }} variant="contained" color="primary" onClick={handleSearch}>
        Search
      </Button>
    </Box>
  );
}

export default SearchBar;
