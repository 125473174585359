import React from 'react'
import {
  Box, IconButton, Tooltip, Typography,
} from '@mui/material';
import CustomTable from 'components/CustomTable'
import { TColumns, TRows } from 'components/CustomTable/interface';
import { ACTION_ITEM_TOOLTIP_TEXT, ChartColors, ChartLabels } from 'utils/constants';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ICandidateSummary } from 'reduxtk/interfaces';

interface IcandidateList {
  searchValue:string,
  competencyFilter: string,
  onSelect: Function,
  candidateSummaryData: ICandidateSummary[]
  highlightby: number
}

export function getLabelByValue(value: number) {
  if (value < 50) {
    return { color: ChartColors.Less50, label: ChartLabels.Less50 };
  } if (value >= 50 && value < 60) {
    return { color: ChartColors.C50to60, label: ChartLabels.C50to60 };
  } if (value >= 60 && value < 70) {
    return { color: ChartColors.C60to70, label: ChartLabels.C60to70 };
  } if (value >= 70 && value < 85) {
    return { color: ChartColors.C70to85, label: ChartLabels.C70to85 };
  } if (value >= 85 && value <= 100) {
    return { color: ChartColors.C85to100, label: ChartLabels.C85to100 };
  }
  return { color: 'white', label: 'Not qualified' };
}

export function getQualificationIcon(qualificationPercent: number) {
  return (
    <Box
      display="flex"
      alignContent="center"
      justifyContent="center"
      bgcolor={getLabelByValue(qualificationPercent)?.color}
      color="white"
      width="70%"
    >
      <Typography variant="body1" gutterBottom mb={0}>
        {`${qualificationPercent}%`}
      </Typography>
    </Box>
  )
}

export function getHierarchyIcon(hierarchy: string) {
  return (
    <Box
      display="flex"
      alignItems="center"
      pl={1}
      pr={1}
      bgcolor="#7c7b7b"
      color="white"
      width="fit-content"
    >
      <Typography variant="body1" gutterBottom mb={0}>
        {` ${hierarchy} `}
      </Typography>
    </Box>
  )
}

export default function CandidateList({
  searchValue, competencyFilter, onSelect, candidateSummaryData, highlightby,
}: IcandidateList) {
  function getViewButton(candidate: ICandidateSummary, viewCandidate: Function) {
    return (
      <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.MORE_INFO}>
        <IconButton sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => viewCandidate(candidate)}>
          <VisibilityIcon sx={{ color: (theme) => theme.palette.primary.main }} />
          <Typography color={(theme) => theme.palette.primary.main}>View</Typography>
        </IconButton>
      </Tooltip>
    )
  }

  const dataSet: TColumns[] = [
    {
      name: 'Candidate Id',
      column: 'id',
      width: 30,
      sortable: true,
      align: 'center',
    },
    {
      name: 'Name',
      column: 'name',
      width: 120,
      sortable: true,
    },
    {
      name: 'Current Job Position',
      column: 'currentPosition',
      width: 250,
      sortable: true,
    },
    {
      name: 'Hierarchy',
      column: 'currentHierarchyIcon',
      width: 100,
      sortable: true,
    },
    {
      name: 'Required Match',
      column: 'QualificationIcon',
      width: 100,
      sortable: true,
    },
    {
      name: 'Preferred',
      column: 'preferredCandidateQualify',
      alignTitle: 'center',
      align: 'center',
      width: 100,
      sortable: true,
    },
    {
      name: 'Overall',
      column: 'overAllCandidateQualify',
      width: 100,
      sortable: true,
      alignTitle: 'center',
      align: 'center',
    },
    {
      name: 'Action',
      column: 'Action',
      width: 90,
      sortable: false,
    },
  ];

  const candidateDataSet: TRows[] = (candidateSummaryData)
    .filter((item) => item.name?.toUpperCase().includes(searchValue?.toUpperCase())
      && (competencyFilter === 'All' || getLabelByValue(item?.requiredCandidateQualify)?.label === competencyFilter))
    .map(
      (item: ICandidateSummary) => ({
        ...item,
        id: item?.candidateId,
        Qualification: item?.requiredCandidateQualify,
        QualificationIcon: getQualificationIcon(item?.requiredCandidateQualify),
        currentHierarchy: item?.currentHierarchy,
        currentHierarchyIcon: getHierarchyIcon(item?.currentHierarchy),
        overAllCandidateQualify: `${item?.overAllCandidateQualify}%`,
        preferredCandidateQualify: `${item?.preferredCandidateQualify}%`,
        Action: getViewButton(item, onSelect),
        isCollapsable: false,
        details: [],
      }),
    );

  return (
    <CustomTable
      rows={candidateDataSet}
      columns={dataSet}
      collapseAllRow={false}
      defaultSort="Qualification"
      highlightRowBy={highlightby}
    />
  )
}
