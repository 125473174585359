/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
// import { ChartColors, ChartLabels } from 'utils/constants';
import { Box, Stack, Typography } from '@mui/material';
import { ChartData } from '.';

export default function StackedBarChart({ chartDataSet, totalCandidates }: { chartDataSet: ChartData, totalCandidates: number }) {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    if (chartDataSet) {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue('--text-color');
      const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
      const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
      const data = {
        labels: chartDataSet.labels.slice(0, 5),
        datasets: chartDataSet.datasets.slice(0, 5),
      };
      const options = {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          legend: {
            position: 'bottom',
            labels: {
              color: textColor,
            },
          },
          datalabels: {
            color: textColorSecondary,
          },
        },
        scales: {
          x: {
            stacked: true,
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
          y: {
            stacked: true,
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
        },
      };

      setChartData(data);
      setChartOptions(options);
    }
  }, [chartDataSet]);

  const getEmptyMessage = () => (
    <Stack display="flex" direction="column" justifyContent="center" alignItems="center" height="30vh">
      <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>No Results Found!</Typography>
      <Typography variant="caption" color="gray">Please try removing or changing your filters</Typography>
    </Stack>
  )

  const getChartBox = () => (
    <>
      <Chart type="bar" data={chartData} options={chartOptions} />
      <Typography variant="caption" color="gray">Require Match Percentage</Typography>
    </>
  )

  return (
    <Box minWidth="100%" pt={1} textAlign="center">
      {
        totalCandidates === 0
          ? getEmptyMessage() : getChartBox()
      }
    </Box>
  )
}
