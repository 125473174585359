/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { TextField } from '@mui/material';

function TextFieldStyled({ ...props }) {
  return (
    <TextField
      sx={{
        width: '100%',
        borderRadius: 'none',
        paddingRight: '5px',
      }}
      InputLabelProps={{ shrink: false }}
      size="small"
      {...props}
    />
  )
}

export default TextFieldStyled
