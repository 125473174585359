import React, { useState } from 'react'
import ViewFormLayout from 'components/ViewFormLayout'
import { Typography, Stack, MenuItem } from '@mui/material'
import { useSelector } from 'react-redux';
import { IState } from 'reduxtk/interfaces';
import SelectStyled from 'components/SelectStyled';
import { useFutureJobPositionsQuery } from 'services/dataApi';
import LoadingIcon from 'components/LoadingIcon';
import { JobPosition } from 'services/interfaces';
import CandidateToJobPosition from './CandidateToJobPosition';

export default function JobPositionToCandidate() {
  const {
    userId,
    firstName: FirstName,
    lastName: LastName,
    jobPositionId,
    jobPositionDescription: JobPositionDescription,
  } = useSelector((state: IState) => state.userData.user);

  const [jobPositionSelected, setJobPositionSelected] = useState<Number>(0);

  const {
    data: jobPositionData,
    isLoading: isLoadingJobPosition,
  } = useFutureJobPositionsQuery({ userId });

  const handleSelection = (selectedValue: any) => {
    setJobPositionSelected(selectedValue.target.value);
  };

  function getCompetencyAnalysisCompare() {
    if (jobPositionData) {
      return (
        <Stack flexDirection="column" gap={2} justifyContent="center" p={2}>
          <Stack flexDirection="row" gap={2} alignItems="center">
            <Typography>Current Job Position:</Typography>
            <Typography>{JobPositionDescription}</Typography>
          </Stack>
          <Stack flexDirection="row" gap={2} alignItems="center" width="100%">
            <Typography>Future Job Position:</Typography>
            <SelectStyled
              placeholder="Future Job Position"
              id="job-position"
              value={jobPositionSelected}
              onChange={(e) => handleSelection(e)}
            >
              <MenuItem value={0}>Select a Job Position</MenuItem>
              {React.Children.toArray(
                jobPositionData?.map((
                  { jobPositionId, jobPositionDescription }: JobPosition,
                ) => <MenuItem value={jobPositionId}>{jobPositionDescription}</MenuItem>),
              )}
            </SelectStyled>
          </Stack>
        </Stack>
      )
    }
    if (jobPositionId) {
      return (
        <Stack flexDirection="row" gap={2} alignItems="center" justifyContent="center" width="100%" p={2}>
          <Typography>
            Please assign Future one or more Job Positions to get competency analysis!
          </Typography>
        </Stack>
      )
    }
    return null
  }

  return (
    <ViewFormLayout
      testId="competency-analysis"
    >
      <ViewFormLayout.Title>
        {jobPositionId
          ? (
            <Stack direction="column" spacing={1}>
              <Typography variant="subtitle1">{`Succession Planning: ${FirstName} ${LastName} (${userId})`}</Typography>
            </Stack>
          )
          : (
            <Stack direction="column" p={1}>
              <Typography variant="subtitle1" textAlign="center">Please assign Job Position to get competency analysis!</Typography>
            </Stack>
          )}
      </ViewFormLayout.Title>

      <ViewFormLayout.Body>
        {isLoadingJobPosition ? <LoadingIcon /> : getCompetencyAnalysisCompare()}
        {
          jobPositionSelected !== 0 && (
            <Stack flexDirection="column" gap={2} justifyContent="center" p={2}>
              <CandidateToJobPosition
                showQualify
                candidateId={Number(userId)}
                jobPositionId={jobPositionSelected}
              />
            </Stack>

          )
        }
      </ViewFormLayout.Body>
    </ViewFormLayout>
  )
}
