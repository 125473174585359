/* eslint-disable max-len */
import React from 'react';
import Chart from 'chart.js/auto';
import { ArcElement, ChartOptions } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Box, Typography } from '@mui/material';
import { ICandidateSummary } from 'reduxtk/interfaces';
import { ChartColors, ChartLabels } from 'utils/constants';

Chart.register(ArcElement);

function countCompetency(_candidates: ICandidateSummary[]): number[] {
  const competency = Array(5).fill(0);

  _candidates.forEach((candidate: ICandidateSummary) => {
    if (candidate?.overAllCandidateQualify < 50) {
      competency[0] += 1;
    } else if (candidate?.requiredCandidateQualify >= 50 && candidate?.requiredCandidateQualify < 60) {
      competency[1] += 1;
    } else if (candidate?.requiredCandidateQualify >= 60 && candidate?.requiredCandidateQualify < 70) {
      competency[2] += 1;
    } else if (candidate?.requiredCandidateQualify >= 70 && candidate?.requiredCandidateQualify < 85) {
      competency[3] += 1;
    } else if (candidate?.requiredCandidateQualify >= 85 && candidate?.requiredCandidateQualify <= 100) {
      competency[4] += 1;
    }
  });
  return competency;
}

function UserStatus(
  { onSelect, candidates }:
  {onSelect: Function, candidates: ICandidateSummary[]},
) {
  const chartData = {
    labels: [
      ChartLabels.Less50,
      ChartLabels.C50to60,
      ChartLabels.C60to70,
      ChartLabels.C70to85,
      ChartLabels.C85to100,
    ],
    borderWidth: 1,
    datasets: [
      {
        data: countCompetency(candidates),
        backgroundColor: [
          ChartColors.Less50,
          ChartColors.C50to60,
          ChartColors.C60to70,
          ChartColors.C70to85,
          ChartColors.C85to100,
        ],
      },
    ],
  };

  const handleSliceClick = (elements: any) => {
    if (elements && elements[0]) {
      const element = chartData.labels[elements[0].index];
      onSelect(element)
    }
  };

  const chartOptions: ChartOptions = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      subtitle: {
        display: true,
        position: 'bottom',
        fullSize: true,
      },
    },
    maintainAspectRatio: false,
    responsive: false,
    onClick: (event, elements) => handleSliceClick(elements),
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
        {`Total candidates ${chartData?.datasets[0]?.data.reduce((accumulator, currentValue) => accumulator + currentValue, 0)}`}
      </Typography>
      <Doughnut
        data={chartData}
        options={chartOptions}
        data-testid="user-courses-stats"
        height="330px"
        width="330px"
      />
      <Typography variant="subtitle2" sx={{ color: '#9e9e9e', fontWeight: 500 }}>Required Match</Typography>
    </Box>

  );
}

export default UserStatus;
