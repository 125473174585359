/* eslint-disable no-unused-vars */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const yfBaseUrlApi = process.env.REACT_APP_YELLOWFIN_URL;
const yfUsername = process.env.REACT_APP_YELLOWFIN_USERNAME;
const yfUserPassword = process.env.REACT_APP_YELLOWFIN_PAASSWORD;

export const yellowfinApi = createApi({
  reducerPath: 'yellowfinServerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: yfBaseUrlApi,
    prepareHeaders: (headers) => {
      const ts = +new Date();
      headers.set('Authorization', `YELLOWFIN ts=${ts}, nonce=random`);
      headers.set('Accept', 'application/vnd.yellowfin.api-v1+json');
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getSSOToken: builder.query({
      query: (yfClientOrgRef) => ({
        url: 'api/rpc/login-tokens/create-sso-token',
        method: 'POST',
        body: {
          signOnUser: {
            userName: yfUsername,
            password: yfUserPassword,
            clientOrgRef: yfClientOrgRef,
          },
          loginParameters: ['YFTOOLBAR=FALSE'],
          noPassword: false,
          customParameters: 'Some custom integration value',
          adminUser: {
            userName: yfUsername,
            password: yfUserPassword,
          },
        },
      }),
    }),
  }),
});

export const { useGetSSOTokenQuery } = yellowfinApi;
