import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { ICandidateSummary } from 'reduxtk/interfaces'
import { JobPosition } from 'services/interfaces'
import { getLabelByValue } from './CandidateList'
import CandidateToJobPosition from './CandidateToJobPosition'

export function CircleCard(
  { value, color, type }:
  {value: number, color: string, type: string},
) {
  return (
    <Stack display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Box
        bgcolor={color}
        color={type === 'Required' ? 'white' : '#bdbdbd'}
        width={100}
        borderRadius="50%"
        height={100}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h6" sx={{ fontWeight: 700 }}>{`${value}%`}</Typography>
      </Box>
      <Typography variant="subtitle2" sx={{ color: '#9e9e9e', fontWeight: 500 }}>{`${type} Match`}</Typography>
    </Stack>

  )
}

export default function CandidateInfo({ candidate, jobPositionInfo }:
  {candidate: ICandidateSummary, jobPositionInfo: JobPosition}) {
  return (
    <Stack display="flex" flexDirection="column" justifyContent="center" gap={3} p={1}>
      <Stack display="flex" flexDirection="row" alignContent="center" gap={6}>
        <CircleCard
          color={getLabelByValue(candidate?.requiredCandidateQualify || 0)?.color}
          value={Number(candidate?.requiredCandidateQualify)}
          type="Required"
        />
        <CircleCard
          color="#eeeeee"
          value={Number(candidate?.preferredCandidateQualify)}
          type="Preferred"
        />
        <CircleCard
          color="#f5f5f5"
          value={Number(candidate?.overAllCandidateQualify)}
          type="Overall"
        />
        <Stack direction="column" spacing={1} justifyContent="center">
          <Typography>{`Candidate: ${candidate?.name}`}</Typography>
          <Typography variant="subtitle1">{`Future job position: ${jobPositionInfo?.jobPositionDescription}`}</Typography>
        </Stack>
      </Stack>
      <CandidateToJobPosition
        candidateId={candidate?.candidateId}
        jobPositionId={jobPositionInfo?.jobPositionId}
      />
    </Stack>
  )
}
