export default function sortingArrayBy(
  { sortDirection, sortBy, arrayToSort }:
    {sortDirection: string, sortBy: string, arrayToSort: any[]},
) {
  const DESCENDING = 'desc';
  return sortBy ? arrayToSort.slice().sort((a, b) => {
    const getValue = (item: any) => {
      const value = item[sortBy];

      if (value instanceof Date) {
        return value.getTime();
      } if (!Number.isNaN(Date.parse(value))) {
        return new Date(value).getTime();
      } if (!Number.isNaN(parseFloat(value)) && Number.isFinite(value)) {
        return parseFloat(value);
      }
      return String(value)?.toUpperCase();
    };

    const fieldA = getValue(a);
    const fieldB = getValue(b);

    let comparison = 0;

    if (fieldA > fieldB) {
      comparison = 1;
    } else if (fieldA < fieldB) {
      comparison = -1;
    }
    return sortDirection === DESCENDING ? comparison * -1 : comparison;
  }) : arrayToSort
}
