import React, { useRef } from 'react';
import {
  Container, Box, Button, TextField, Select, MenuItem,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function UserAccess() {
  const navigate = useNavigate();

  const userIdRef = useRef<HTMLInputElement>(null);
  const tokenRef = useRef<HTMLInputElement>(null);
  const goToRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    const userId = userIdRef?.current?.value;
    const token = tokenRef?.current?.value;
    const goTo = goToRef?.current?.value;
    navigate(`/sso/${userId}/${token}/${goTo}`)
  };

  return (
    <Container
      maxWidth={false}
      style={{
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh', backgroundImage: 'url("https://lsglm500.learnsoft.com/LSGLM/Login/images/back2.jpg")',
      }}
    >
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minWidth="500px" gap={2}>
        <TextField
          label="User ID"
          variant="outlined"
          inputRef={userIdRef}
          fullWidth
        />
        <TextField
          label="Token"
          variant="outlined"
          inputRef={tokenRef}
          fullWidth
        />
        <Select
          label="Go To"
          placeholder="Go To page"
          defaultValue=" "
          inputRef={goToRef}
          fullWidth
          variant="outlined"
        >
          <MenuItem value=" ">Select a page to go</MenuItem>
          <MenuItem value="dashboard">Dashboard</MenuItem>
          <MenuItem value="dashboard-chart">Dashboard Chart</MenuItem>
          <MenuItem value="admin/dashboard">Admin Dashboard</MenuItem>
          <MenuItem value="admin/dashboard-chart">Admin Dashboard Chart</MenuItem>
          <MenuItem value="competency-analysis">Competency Analysis</MenuItem>
        </Select>
        <Button onClick={handleClick} variant="contained">Go</Button>
      </Box>
    </Container>
  );
}
