/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { IUserDetails, IUserProfile2 } from 'reduxtk/interfaces';
import { DEFAULT_APP_THEME_PRIMARY_COLOR } from 'utils/constants';
import { dataApi } from 'services/dataApi';
import { isUserAnAdmin } from 'utils/helpers';

const userInitialState: IUserDetails = {
  active: 0,
  eMail: '',
  firstName: '',
  lastName: '',
  groups: [],
  userId: 0,
  userLogin: '',
  jobPositionDescription: '',
  jobPositionId: 0,
  employeeNumber: '',
  currentHierarchy: '',
};

const initialState: IUserProfile2 = {
  authenticated: localStorage.getItem('authenticated') === 'true',
  accessToken: localStorage.getItem('accessToken'),
  userId: localStorage.getItem('userId') || '',
  companyName: localStorage.getItem('companyName'),
  PrimaryColor: localStorage.getItem('PrimaryColor') || DEFAULT_APP_THEME_PRIMARY_COLOR,
  user: userInitialState,
  admin: false,
};

export const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setSSOAccessToken: (state, action) => {
      // localStorage.setItem('accessToken', action.payload.accessToken)
      // localStorage.setItem('userId', action.payload.userId)
      state.accessToken = action.payload.accessToken;
      state.userId = action.payload.userId;
    },
    logout: (state) => {
      state.authenticated = false;
      state.user = userInitialState;
      localStorage.setItem('authenticated', 'false')
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userId');
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      dataApi.endpoints.userDetail.matchFulfilled,
      (state, { payload }) => {
        state.authenticated = true;
        state.user = payload
        state.admin = isUserAnAdmin(payload?.groups)
        localStorage.setItem('authenticated', 'true')
      },
    );
  },
});

export const {
  logout, setSSOAccessToken,
} = userDataSlice.actions;

export default userDataSlice.reducer;
