import React from 'react';
import Layout from 'components/Layout';
import SuccessionPlanningDashboard from 'module/successionPlanningDashboard';

export default function AdminDashboardChartPage() {
  return (
    <Layout>
      <SuccessionPlanningDashboard />
    </Layout>
  );
}
