import React from 'react';
import { Box, CircularProgress } from '@mui/material';

function LoadingIcon() {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '8px',
    }}
    >
      <CircularProgress data-testid="loading-icon" />
    </Box>
  );
}

export default LoadingIcon;
