import { BaseQueryApi, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IRequirementCandidate, IState } from 'reduxtk/interfaces';
import { NAVIGATION_URL } from 'utils/constants';
import {
  CriticalRickRequest,
  CriticalRiskResponse,
  GetAgencyResponse, GetDistrictResponse, GetDistrictsRequest, GetDivisionsRequest,
  GetJobPositionsResponse,
} from './interfaces';

const baseUrlApi = process.env.REACT_APP_BASE_URL_SUCCESSION;

export const dataApi: any = createApi({
  reducerPath: 'dataServerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrlApi,
    prepareHeaders: (headers: Headers, api: Pick<
      BaseQueryApi,
      'getState' | 'extra' | 'endpoint' | 'type' | 'forced'
    >) => {
      const getState = api?.getState() as IState;
      const { accessToken } = getState.userData || null;
      // If we have a token set in state, let's assume that we should be passing it.
      if (accessToken) {
        headers.set('authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
    validateStatus: (response: Response): boolean => {
      if (response.status === 401) {
        let rediretUrl = '/login';
        if (window.location.href.includes('sso')) {
          rediretUrl = `/${NAVIGATION_URL.ERROR_LOGIN}`;
        }

        window.location.href = rediretUrl;
        localStorage.removeItem('accessToken');
        localStorage.removeItem('authenticated');
        localStorage.removeItem('userId');
        return false
      }
      return true;
    },
  }),

  endpoints: (builder) => ({
    jobPositionSummary: builder.query({
      query: ({ jobPositionId }) => ({
        url: `/api/JobPositions/${jobPositionId}/Summary`,
        method: 'GET',
        params: {},
      }),
    }),
    userToJobPosition: builder.query<IRequirementCandidate, any>({
      query: ({ jobPositionId, userId }) => ({
        url: `/api/JobPositions/${jobPositionId}/Candidates/${userId}`,
        method: 'GET',
        params: {},
      }),
    }),
    userDetail: builder.query({
      query: ({ userId }) => ({
        url: `/api/User/details/${userId}`,
        method: 'GET',
        params: {},
      }),
    }),
    futureJobPositions: builder.query({
      query: ({ userId }) => ({
        url: `api/User/futurePositions/${userId}`,
        method: 'GET',
      }),
    }),
    getAgencies: builder.query<GetAgencyResponse, {userId: string}>({
      query: ({ userId }) => ({
        url: `api/catalogs/agencies?userId=${userId}`,
        method: 'GET',
      }),
    }),
    getDistricts: builder.query<GetDistrictResponse, GetDistrictsRequest>({
      query: ({ agencyId }) => ({
        url: `/api/catalogs/agencies/${agencyId}/districts`,
        method: 'GET',
      }),
    }),
    getDivisions: builder.query<GetDistrictResponse, GetDivisionsRequest>({
      query: ({ agencyId, districtId }) => ({
        url: `/api/catalogs/agencies/${agencyId}/districts/${districtId}/divisions`,
        method: 'GET',
      }),
    }),
    getJobPositions: builder.query<GetJobPositionsResponse, void>({
      query: () => ({
        url: '/api/catalogs/job-positions',
        method: 'GET',
      }),
    }),
    criticalRisk: builder.mutation<CriticalRiskResponse, CriticalRickRequest>({
      query: ({
        jobPositionIds = [], divisionIds = [], districtIds = [], agencyIds = [],
      }) => ({
        url: '/api/dashboards/critical-risk',
        method: 'POST',
        body: {
          jobPositionIds,
          divisionIds,
          districtIds,
          agencyIds,
        },
      }),
    }),
  }),
});

export const {
  useJobPositionSummaryQuery,
  useUserToJobPositionQuery,
  useUserDetailQuery,
  useFutureJobPositionsQuery,
  useGetAgenciesQuery,
  useGetDistrictsQuery,
  useGetDivisionsQuery,
  useGetJobPositionsQuery,
  useCriticalRiskMutation,
} = dataApi;
