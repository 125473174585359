/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import {
  Typography, Stack, MenuItem,
  Select,
  Checkbox,
  ListItemText,
  Grid,
  FormControl,
  InputLabel,
  Divider,
} from '@mui/material';
import {
  useGetDivisionsQuery, useGetAgenciesQuery, useGetDistrictsQuery, useGetJobPositionsQuery,
  useCriticalRiskMutation,
} from 'services/dataApi';
import {
  Agency, CriticalRickRequest, CriticalRiskResponse, District, Division,
  EmployeeSummaryCriticalRisk, JobPosition,
} from 'services/interfaces';
import { parseCritialRiskToChart, toUpperCapitalCase } from 'utils/helpers';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import LoadingIcon from 'components/LoadingIcon';
import SelectStyled from 'components/SelectStyled';
import ViewFormLayout from 'components/ViewFormLayout';
import { useSelector } from 'react-redux';
import { IState } from 'reduxtk/interfaces';
import StackedBarChart from './StackedBarChart';
import EmployeesResume from './EmployeesResume';

export interface Dataset {
  type: 'bar';
  label: string;
  backgroundColor: string;
  data: number[];
}

export interface ChartData {
  labels: string[];
  datasets: Dataset[];
}

interface ICriticalRisk {
  diagram: ChartData;
  summary: EmployeeSummaryCriticalRisk[]
}
function AdminDashboardChart() {
  const { userId } = useSelector((state: IState) => state?.userData);

  const {
    data: agencyData,
    isLoading: isLoadingAgency,
  } = useGetAgenciesQuery({ userId }, {
    skip: !userId,
  });

  const [agencyId, setAgencyId] = useState<number>(0);
  const [districtId, setDistricId] = useState<number>(0);
  const [divisionId, setDivisionId] = useState<number>(0);
  const [rolId, setRolId] = useState<number[]>([]);
  const [criticalRisk, setCriticalRisk] = useState<ICriticalRisk>();

  const [getCriticalRisk, { isLoading }] = useCriticalRiskMutation();

  const {
    data: jobPositionData,
    isLoading: isLoadingJobPosition,
  } = useGetJobPositionsQuery();

  const { data: districts, isLoadingDistricts } = useGetDistrictsQuery({ agencyId }, {
    skip: !agencyId,
  });

  const { data: divisions, isLoadingDivisions } = useGetDivisionsQuery({ agencyId, districtId }, {
    skip: !(agencyId && districtId),
  });

  const handleAgencyChange = (e: any) => {
    districtId && setDistricId(0)
    divisionId && setDivisionId(0)
    setAgencyId(e?.target?.value);
  }

  const handleGetCriticalRisk = async () => {
    const newJob: CriticalRickRequest = {
      jobPositionIds: rolId,
      divisionIds: divisionId > 0 ? [divisionId] : [],
      districtIds: districtId > 0 ? [districtId] : [],
      agencyIds: agencyId > 0 ? [agencyId] : [],
    };
    try {
      const criticalResponse: CriticalRiskResponse = await getCriticalRisk(newJob).unwrap();
      setCriticalRisk(
        {
          diagram: parseCritialRiskToChart(criticalResponse?.diagram) as ChartData,
          summary: criticalResponse?.employeeSummary,
        },
      )
    } catch (error) {
      // Handle error, e.g., show an error message
      console.error('Failed to add job', error);
    }
  };

  useEffect(() => {
    if (agencyData) {
      setAgencyId(agencyData[0]?.id)
    }
  }, [agencyData]);

  useEffect(() => {
    if (agencyId !== null) {
      handleGetCriticalRisk();
    }
  }, [agencyId, districtId, divisionId, rolId]);

  if (isLoadingAgency || isLoadingDistricts || isLoadingDivisions) {
    return <LoadingIcon />
  }

  return (
    <ViewFormLayout testId="succession">
      <ViewFormLayout.Body>
        <Stack flexDirection="column" alignItems="center" height="100%" gap={2} m={4}>
          <Stack flexDirection="row" gap={2} alignItems="center" justifyContent="center" width="100%">
            <SelectStyled
              minWidth="300px"
              placeholder="Agency"
              label="Agency"
              id="Agency"
              disabled={isLoadingAgency}
              value={agencyId}
              onChange={handleAgencyChange}
            >
              <MenuItem value={0}>Select a agency</MenuItem>
              {React.Children.toArray(
                agencyData?.map((
                  { id, name }: Agency,
                ) => <MenuItem value={id}>{toUpperCapitalCase(name)}</MenuItem>),
              )}
            </SelectStyled>

            <SelectStyled
              minWidth="300px"
              placeholder="Districts"
              label="Districts"
              id="districts"
              disabled={!agencyId || isLoadingDistricts || isLoadingAgency}
              value={districtId}
              onChange={(e: any) => setDistricId(e?.target?.value)}
            >
              <MenuItem value={0}>Select a district</MenuItem>
              {React.Children.toArray(
                districts?.map((
                  { id, name }: District,
                ) => <MenuItem value={id}>{toUpperCapitalCase(name)}</MenuItem>),
              )}
            </SelectStyled>

            <SelectStyled
              minWidth="300px"
              label="Divisions"
              placeholder="Divisions"
              id="division"
              disabled={!agencyId
              || isLoadingDistricts || isLoadingAgency
              || !districtId || isLoadingDistricts || isLoadingDivisions}
              value={divisionId}
              onChange={(e: any) => setDivisionId(e?.target?.value)}
            >
              <MenuItem value={0}>Select a division</MenuItem>
              {React.Children.toArray(
                divisions?.map((
                  { id, name }: Division,
                ) => <MenuItem value={id}>{toUpperCapitalCase(name)}</MenuItem>),
              )}
            </SelectStyled>
            <FormControl>
              <InputLabel id="job-position-label">Job Position</InputLabel>
              <Select
                labelId="job-position-label"
                id="job-position"
                disabled={isLoadingJobPosition}
                maxRows={5}
                multiple
                value={rolId}
                label="Job Position"
                // onClose={handleGetCriticalRisk}
                onChange={(e) => setRolId(e.target.value as number[])}
                renderValue={(selected) => selected.map((id) => {
                  const job = jobPositionData.find(
                    (position: { jobPositionId: number; }) => position.jobPositionId === id,
                  );
                  return job ? job.jobPositionDescription : '';
                }).join(', ')}
                sx={{
                  maxWidth: '400px',
                  minWidth: '400px',
                }}
              >
                {jobPositionData?.map(({ jobPositionId, jobPositionDescription }: JobPosition) => (
                  <MenuItem key={jobPositionId} value={jobPositionId}>
                    <Checkbox checked={rolId.indexOf(jobPositionId) > -1} />
                    <ListItemText primary={toUpperCapitalCase(jobPositionDescription)} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack display="flex" flexDirection="row" gap={2} mt={1} width="100%">
            {isLoading ? (
              <Stack
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexGrow={1}
              >
                <LoadingIcon />
              </Stack>
            )
              : (
                <Grid container gap={2}>
                  <Grid item xs={8}>
                    <Stack flexDirection="row" gap={1} alignItems="center">
                      <StackedBarChartIcon />
                      <Typography>Candidates Successor Readiness</Typography>
                      <Typography color="gray" variant="subtitle2">(This Chart shows up to 5 Job Positions results from the selected filters)</Typography>
                    </Stack>
                    <Divider />
                    <StackedBarChart
                      chartDataSet={criticalRisk?.diagram as ChartData}
                      totalCandidates={criticalRisk?.summary.reduce((accumulator, currentValue) => accumulator + currentValue.groupCount, 0) || 0}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Stack flexDirection="row" gap={1} alignItems="center">
                      <StackedBarChartIcon />
                      <Typography>Candidates</Typography>
                    </Stack>
                    <Divider />
                    <EmployeesResume
                      employeeSummary={criticalRisk?.summary as EmployeeSummaryCriticalRisk[]}
                    />
                  </Grid>

                </Grid>
              )}

          </Stack>
        </Stack>
      </ViewFormLayout.Body>
    </ViewFormLayout>
  );
}

export default AdminDashboardChart;
