/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { IUser, IUserProfile } from 'reduxtk/interfaces';
import { authApi } from 'services/authApi';
import { DEFAULT_APP_THEME_PRIMARY_COLOR } from 'utils/constants';
import { isUserAnAdmin } from 'utils/helpers';
import { JOB_POSITION_DESCRIPTION_EXAMPLE, JOB_POSITION_EXAMPLE } from '../module/succession/shared';

const userInitialState = {
  Active: false,
  AHAURL: [],
  BirthDate: null,
  CDLClassificationType: null,
  CDLExpirationDate: null,
  Company: null,
  CredentialName: null,
  CustomUserFields: [],
  DateHired: '',
  DistributionCode: null,
  DistributionName: null,
  Email: '',
  EmployeeBadgeNumber: null,
  EmployeeID: null,
  EthnicityType: null,
  FirstName: '',
  Gender: null,
  HomeAddress: null,
  LastName: '',
  LeadershipAssessmentLevelName: null,
  LearnerEthnicity: null,
  MergeDate: null,
  MiddleName: null,
  Password: null,
  PhotoPath: null,
  Policy: [],
  PromotionDate: null,
  RehireDate: null,
  SafetySensitive: null,
  SalaryGrade: null,
  SpecialtyName: null,
  SSN: null,
  SubStatus: null,
  SupervisorLevel: null,
  Supervisors: [],
  TerminationDate: null,
  UserGroups: [],
  userId: null,
  UserLicenses: [],
  UserName: '',
  JobPositionDescription: JOB_POSITION_DESCRIPTION_EXAMPLE,
  JobPositionId: JOB_POSITION_EXAMPLE,
};

const initialState: IUserProfile = {
  authenticated: localStorage.getItem('authenticated') === 'true',
  accessToken: localStorage.getItem('accessToken'),
  userId: localStorage.getItem('userId') || '',
  companyName: localStorage.getItem('companyName'),
  PrimaryColor: localStorage.getItem('PrimaryColor') || DEFAULT_APP_THEME_PRIMARY_COLOR,
  user: userInitialState,
  admin: false,
};

type responseData = {
  access_token: string;
  Validate: string;
  userId: string;
  companyName: string;
  user: IUser;
  admin: boolean;
}
export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    login: (state) => {
      state.authenticated = true;
    },
    setSSOAccessToken: (state, action) => {
      localStorage.setItem('accessToken', action.payload.accessToken)
      localStorage.setItem('authenticated', 'true')
      localStorage.setItem('userId', action.payload.userId)
      localStorage.setItem('companyName', action.payload.companyName)
      state.accessToken = action.payload.accessToken;
      state.userId = action.payload.userId;
      state.authenticated = true;
    },
    logout: (state) => {
      state.authenticated = false;
      state.user = userInitialState;
      localStorage.setItem('authenticated', 'false')
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userId');
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.getLearnsoftTokenAndLoggin.matchFulfilled,
      (state, action) => {
        const payload: responseData = action?.payload as responseData;
        if (payload?.access_token) {
          localStorage.setItem('accessToken', payload.access_token)
          state.accessToken = payload.access_token;
        } else {
          localStorage.removeItem('accessToken');
          state.accessToken = null;
        }

        if (payload?.Validate === 'true') {
          localStorage.setItem('authenticated', 'true')
          localStorage.setItem('userId', payload.userId)
          localStorage.setItem('companyName', payload.companyName)
          state.authenticated = true;
          state.companyName = payload.companyName;
          state.userId = payload.userId;
          state.user = {
            ...payload?.user,
            JobPositionId: JOB_POSITION_EXAMPLE,
            JobPositionDescription: JOB_POSITION_DESCRIPTION_EXAMPLE,
          };
          state.admin = payload?.admin;
        } else {
          localStorage.setItem('authenticated', 'false')
          localStorage.removeItem('companyName')
          localStorage.removeItem('PrimaryColor')
          localStorage.removeItem('admin')
          state.authenticated = false;
          state.admin = false;
        }
      },
    );
    builder.addMatcher(
      authApi.endpoints.userDetail.matchFulfilled,
      (state, { payload }) => {
        state.user = { ...userInitialState, ...payload?.Users?.[0] }
        state.admin = isUserAnAdmin(payload?.Users?.[0].UserGroups)
      },
    );
  },
});

export const {
  login, logout, setSSOAccessToken,
} = userProfileSlice.actions;

export default userProfileSlice.reducer;
