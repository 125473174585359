import React from 'react';
import {
  Box, CssBaseline, Grid,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { IState } from 'reduxtk/interfaces';
import { DEFAULT_APP_THEME_PRIMARY_COLOR } from 'utils/constants';

function Layout({ children }: { children : React.ReactNode}) {
  const { PrimaryColor } = useSelector((state: IState) => state.userProfile);

  const mdTheme = createTheme({
    palette: {
      primary: {
        main: String(PrimaryColor?.trim()).length > 0
          ? PrimaryColor : DEFAULT_APP_THEME_PRIMARY_COLOR,
        light: '#397BD3',
        dark: '#053E8C',
        contrastText: '#fff',
      },
      secondary: {
        main: '#F2395A',
        light: '#F4607B',
        dark: '#A9273E',
        contrastText: '#fff',
      },
      error: {
        main: '#D32F2F',
        light: '#D32F2F',
        dark: '#932020',
        contrastText: '#fff',
      },
      warning: {
        main: '#ED6C02',
        light: '#F08934',
        dark: '#A54B01',
        contrastText: '#fff',
      },
      info: {
        main: '#0288D1',
        light: '#03A9F4',
        dark: '#01579B',
        contrastText: '#fff',
      },
      success: {
        main: '#2E7D32',
        light: '#57975B',
        dark: '#205723',
        contrastText: '#fff',
      },
      divider: 'rgba(0,0,0,0.12)',
    },
  });

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex', height: '100vh', backgroundColor: (theme) => theme?.palette?.grey[100] }} p={4}>
        <CssBaseline />
        <Grid
          container
          display="flex"
          direction="column"
        >
          {children}
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

export default Layout;
