/* eslint-disable max-len */
import { IUserGroup } from 'reduxtk/interfaces';
import { JobPositionCriticalRisk } from 'services/interfaces';
import {
  ADMIN_USER_IDS, PERCENT_LABELS, PERCENT_LABELS_COLOR,
} from 'utils/constants';

// eslint-disable-next-line import/prefer-default-export
export function isStringEmptyOrInvalid(str: any) {
  return str === null || str?.trim() === '' || str?.trim() === 'null';
}

export function isUserAnAdmin(groups: IUserGroup[]) {
  if (!groups) return false;
  const admin = groups.find((group) => {
    if (group.groupID && group.userGroupActive) {
      return ADMIN_USER_IDS.includes(group.groupID) && group.userGroupActive
    }
    return false;
  });

  return !!admin
}

type SortDirection = 'asc' | 'desc';

interface SortOptions<T> {
  sortDirection: SortDirection;
  sortBy: string;
  arrayToSort?: T[];
}

export default function sortingArrayBy<T>({
  sortDirection,
  sortBy,
  arrayToSort = [],
}: SortOptions<T>): T[] {
  const DESCENDING = 'desc';
  return sortBy
    ? arrayToSort.slice().sort((a, b) => {
      const getValue = (item: T) => {
        const value = item[sortBy];

        if (value instanceof Date) {
          return value.getTime();
        } if (typeof value === 'string' && !Number.isNaN(Date.parse(value))) {
          return new Date(value).getTime();
        } if (typeof value === 'number' && Number.isFinite(value)) {
          return value;
        } if (typeof value === 'string') {
          return value.toUpperCase();
        }
        return value;
      };

      const fieldA = getValue(a);
      const fieldB = getValue(b);

      let comparison = 0;

      if (fieldA > fieldB) {
        comparison = 1;
      } else if (fieldA < fieldB) {
        comparison = -1;
      }
      return sortDirection === DESCENDING ? comparison * -1 : comparison;
    })
    : arrayToSort;
}

export function toUpperCapitalCase(text: string) {
  return text
    .toLowerCase()
    .split(' ')
    .map((word) => word
      .split('/')
      .map((subWord) => subWord.charAt(0).toUpperCase() + subWord.slice(1))
      .join('/'))
    .join(' ');
}
export function parseCritialRiskToChart(diagramArray: JobPositionCriticalRisk[]) {
  const labels = diagramArray.map((item) => toUpperCapitalCase(item.jobCodeDescription));
  const datasets = PERCENT_LABELS.map((label: string) => ({
    type: 'bar',
    label: toUpperCapitalCase(label),
    backgroundColor: PERCENT_LABELS_COLOR[label],
    data: diagramArray.map((item) => {
      const summary = item.employeeSummary.find((summary) => summary.percentDescription === label);
      return summary ? summary.groupCount : 0;
    }),
  }));

  return {
    labels,
    datasets,
  };
}
