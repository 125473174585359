import React from 'react';
import {
  BrowserRouter as Router, Routes, Route,
} from 'react-router-dom';
import AdminDashboard from 'pages/admin-dashboard';
import Error from 'pages/error';
import ErrorLogin from 'pages/error-login';
import LearnerDashboard from 'pages/learner-dashboard';
import Login from 'pages/login';
import CompetencyAnalysis from 'pages/competency-analysis';
import ProtectedAdminRoutes from 'components/ProtectedAdminRoutes';
import ProtectedRoutes from 'components/ProtectedRoutes';
import Sso from 'pages/sso';
import AdminError from 'pages/admin-error';
import UserAccess from 'pages/user-access';
import { NAVIGATION_URL } from 'utils/constants';
import AdminDashboardChartPage from 'pages/admin-dashboard-chart';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<UserAccess />} />
        <Route
          path="/sso/:userId/:accessToken/:urlModule/:urlPath"
          element={<Sso />}
        />
        <Route
          path="/sso/:userId/:accessToken/:urlModule"
          element={<Sso />}
        />
        <Route path="/" element={<ProtectedRoutes />}>
          <Route path={NAVIGATION_URL.DASHBOARD} element={<LearnerDashboard />} />
          <Route path={NAVIGATION_URL.COMPETENCY_ANALYSIS} element={<CompetencyAnalysis />} />
          <Route path="dashboard-chart" element={<AdminDashboardChartPage />} />
          <Route path="admin" element={<ProtectedAdminRoutes />}>
            <Route path="dashboard-chart" element={<AdminDashboardChartPage />} />
            <Route path="dashboard" element={<AdminDashboard />} />
          </Route>
        </Route>

        <Route path={`/${NAVIGATION_URL.ERROR_LOGIN}`} element={<ErrorLogin />} />
        <Route path={`/${NAVIGATION_URL.ADMIN_ERROR}`} element={<AdminError />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </Router>
  );
}

export default App;
