import React from 'react';
import {
  Container, Box, Button, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { NAVIGATION_URL } from 'utils/constants';

export default function Error() {
  const navigate = useNavigate();

  return (
    <Container
      maxWidth={false}
      style={{
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh', backgroundImage: 'url("https://lsglm500.learnsoft.com/LSGLM/Login/images/back2.jpg")',
      }}
    >
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Typography className="main-text" variant="h1">
          404
        </Typography>
        <Typography className="secondary-text" variant="h6">
          The page you’re looking for doesn’t exist.
        </Typography>
        <Button onClick={() => navigate(`/${NAVIGATION_URL.DASHBOARD}`)} variant="contained">Back Home</Button>
      </Box>
    </Container>
  );
}
