export const CHECK_TOKEN_EVERY_TIME = 600000;
// export const DEFAULT_APP_THEME_PRIMARY_COLOR = '#085AC9';
export const DEFAULT_APP_THEME_PRIMARY_COLOR = '#003c7c';
export const ADMIN_USER_IDS = [1, 3, 4, 12];
export type TOptions = {
    [key: string]: string,
  };
export const ACTION_ITEM_TOOLTIP_TEXT: TOptions = {
  MORE_INFO: 'More information',
  BACK: 'Go back to previous page',
  SAVE: 'Save changes',
};

export const NAVIGATION_URL = {
  DASHBOARD: 'dashboard',
  DASHBOARD_CHART: 'dashboard-chart',
  COMPETENCY_ANALYSIS: 'competency-analysis',
  COMPETENCY_ANALYSIS_ERROR: 'competency-analysis-error',
  ADMIN: 'admin',
  ERROR_LOGIN: 'error-login',
  ADMIN_ERROR: 'admin-error',
}

export enum ChartLabels {
  Less50 = '< 50%',
  C50to60 = '50% - 60%',
  C60to70 = '60% - 70%',
  C70to85 = '70% - 85%',
  C85to100 = '85% - 100%',
  C80to100 = '80% - 100%',
}

export const PERCENT_LABELS: string[] = [
  ChartLabels.Less50,
  ChartLabels.C50to60,
  ChartLabels.C60to70,
  ChartLabels.C70to85,
  ChartLabels.C85to100,
];

export enum ChartColors {
  Less50 = '#f44336',
  C50to60 = '#ff9800',
  C60to70 = '#3f51b5',
  C70to85 = '#2196f3',
  C85to100 = '#4caf50',
  C80to100 = '#4caf50',
}
interface StringKeyValue {
  [key: string]: string;
}

export const PERCENT_LABELS_COLOR: StringKeyValue = {
  [ChartLabels.Less50]: ChartColors.Less50,
  [ChartLabels.C50to60]: ChartColors.C50to60,
  [ChartLabels.C60to70]: ChartColors.C60to70,
  [ChartLabels.C70to85]: ChartColors.C70to85,
  [ChartLabels.C85to100]: ChartColors.C85to100,
  [ChartLabels.C80to100]: ChartColors.C80to100,
};
